import React from 'react';
import loadable from '@loadable/component';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';

import { AppWidgetProps } from '../../../types/common';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { openBillingPage } from '../../../utils/openBillingPage';

const LazyList = loadable(() => import('./List'));
const LazyStatus = loadable(() => import('./Status'));
const LazyCheckout = loadable(() => import('./Checkout'));
const LazyRestricted = loadable(() => import('./Restricted'));
const LazyUpgradeModal = loadable(() => import('./UpgradeModal'));

const Widget: React.FC<AppWidgetProps> = React.memo((props) => {
  const settings = useSettings();
  const settingsAdapter = React.useMemo(() => new DefaultSettingsAdapter(settings, settingsParams), [settings]);
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <ViewSwitch {...props} />
        <UpgradeModal {...props} />
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
});

const UpgradeModal: React.FC<AppWidgetProps> = ({
  showUpgradeModal,
  closeUpgradeModal,
  subPage,
  continueToDemoCheckout,
  metaSiteId,
  biUpgradeReferralClick,
}) => {
  const onUpgrade = React.useCallback(() => {
    biUpgradeReferralClick('checkout-upgrade-modal');
    metaSiteId && openBillingPage(metaSiteId);
  }, [biUpgradeReferralClick, metaSiteId]);

  return showUpgradeModal ? (
    <LazyUpgradeModal
      onClose={closeUpgradeModal}
      onPreview={subPage.name === 'list' ? continueToDemoCheckout : closeUpgradeModal}
      onUpgrade={onUpgrade}
    />
  ) : null;
};

const ViewSwitch: React.FC<AppWidgetProps> = React.memo((props) => {
  const { subPage } = props;
  switch (subPage.name) {
    case 'list':
    default:
      return <LazyList {...props} />;
    case 'checkout':
      return (
        <LazyCheckout
          {...props}
          viewMode={props.host.viewMode}
          siteStyles={props.host.style}
          integrationData={subPage.checkoutData.integrationData}
        />
      );
    case 'status':
      return (
        <LazyStatus
          {...props}
          ownerDemo={subPage.statusData.ownerDemo}
          integrationData={subPage.statusData.purchaseData.checkoutData.integrationData}
        />
      );
    case 'restricted':
      return <LazyRestricted {...props} />;
  }
});

export default Widget;
